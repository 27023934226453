import { RegisterComponent } from '@/common/register-component';
import { debug } from '@/utils/utils';
import { Component, Host, Prop, State, Watch, h } from '@stencil/core';
import { ObservableMap } from '@stencil/store';

export type Stores = Record<string, ObservableMap<any>>;

export type Transitions = 'none' | 'fade' | 'slide' | 'swipe';

@Component({
  tag: 'nylas-if-state',
  styleUrl: 'nylas-if-state.css',
  shadow: true,
})
export class NylasIfState {
  /**
   * The name of the state to watch.
   */
  @Prop() readonly state?: string;
  /**
   * If true, then the component will render if the state is set (empty or not set).
   * This prop is used with the `state` prop.
   */
  @Prop() readonly isStateSet?: boolean;
  /**
   * If true, then the component will render if the state is true.
   * This prop is used with the `state` prop.
   */
  @Prop() readonly isBoolean?: boolean;
  @Prop() readonly getStore?: <K extends keyof Stores>(name: K) => Stores[K];
  @Prop() readonly transition: Transitions = 'none';

  @State() show: boolean = false;
  @State() classes: Record<string, boolean> = {};

  constructor() {
    this.initTransition = this.initTransition.bind(this);
  }

  connectedCallback() {}

  disconnectedCallback() {}

  async componentWillLoad() {
    debug(`[nylas-if-state] Component will load`);
    this.watchStore(true);
  }

  async componentDidLoad() {
    debug(`[nylas-if-state] Component did load`);
  }

  private shouldRender() {
    // If a period exists in the state name, then we need to split the state name into the store name and the state name.
    // For example, if the state name is `nylas.scheduler.selectableDates`, then the store name is `nylas` and the state name is `scheduler.selectableDates`.
    const [storeName, stateName] = this.state ? this.state.split('.') : ['', ''];
    const getStore = this.getStore;
    if (typeof getStore === 'undefined') {
      debug(`[nylas-if-state] Store name: ${storeName} | State name: ${stateName}`, { getStore });
      return false;
    }

    const store = getStore(storeName as keyof Stores);
    if (typeof store === 'undefined') {
      debug(`[nylas-if-state] Store name: ${storeName} | State name: ${stateName}`, { store });
      return false;
    }

    const state = store.state[stateName as string];

    if (typeof this.isStateSet === 'boolean') {
      return (
        (this.isStateSet === false && (typeof state === 'undefined' || state === null || state.length === 0)) ||
        (this.isStateSet === true && typeof state !== 'undefined' && state !== null)
      );
    }

    if (typeof this.isBoolean === 'boolean') {
      return (this.isBoolean === true && typeof state !== 'undefined' && state === true) || (this.isBoolean === false && typeof state !== 'undefined' && state === false);
    }

    return false;
  }

  @Watch('getStore')
  onGetStoreChange() {
    this.watchStore(true);
  }

  private watchStore(immediate: boolean = false) {
    // If a period exists in the state name, then we need to split the state name into the store name and the state name.
    // For example, if the state name is `nylas.scheduler.selectableDates`, then the store name is `nylas` and the state name is `scheduler.selectableDates`.
    const [storeName, stateName] = this.state ? this.state.split('.') : ['', ''];
    const getStore = this.getStore;
    if (typeof getStore === 'undefined') {
      debug(`[nylas-if-state] Store name: ${storeName} | State name: ${stateName}`, { getStore });
      return null;
    }

    const store = getStore(storeName as keyof Stores);
    if (typeof store === 'undefined') {
      debug(`[nylas-if-state] Store name: ${storeName} | State name: ${stateName}`, { store });
      return null;
    }

    if (immediate === true) {
      this.initTransition();
    }

    store.onChange(stateName as string, this.initTransition);
  }

  private initTransition() {
    const show = this.shouldRender();
    switch (this.transition) {
      case 'fade':
      case 'slide':
      case 'swipe':
        this.classes = {
          [`${this.transition}-in`]: show,
          [`${this.transition}-out`]: !show,
        };
        setTimeout(() => {
          this.show = show;
        }, 150);
        break;
      default:
        this.show = this.shouldRender();
        break;
    }
  }

  @RegisterComponent({
    name: 'nylas-if-state',
    getStoresToProp: 'getStore',
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class={this.classes}>{this.show && <slot></slot>}</div>
      </Host>
    );
  }
}
