:host {
  display: block;
}

div {
  height: inherit;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fade-in {
  animation: fadeIn 1.5s;
}

.fade-out {
  animation: fadeOut 1.5s;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

.slide-in {
  animation: slideIn 1.5s;
}

.slide-out {
  animation: slideOut 1.5s;
}

@keyframes swipeIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.swipe-in {
  animation: swipeIn 1.5s;
}

.swipe-out {
  animation: swipeOut 1.5s;
}
