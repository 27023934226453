import { RegisterComponent } from '@/common/register-component';
import { NylasSchedulerConfigConnector } from '@/connector/nylas-scheduler-config-connector';
import { checkForMissingSlots, debug } from '@/utils/utils';
import { AttachInternals, Component, Element, Host, h } from '@stencil/core';
import { NylasSchedulerEditor } from '../nylas-scheduler-editor/nylas-scheduler-editor';

/**
 * The `nylas-form-card` component is a card that can be used to display a form. It is a simple wrapper around a card with a header and content area.
 */
@Component({
  tag: 'nylas-form-card',
  styleUrl: 'nylas-form-card.scss',
  shadow: true,
  formAssociated: true,
})
export class NylasFormCard {
  @Element() host!: HTMLElement;
  @AttachInternals() internals!: ElementInternals;

  connectedCallback() {
    debug('nylas-event-card', 'connectedCallback');
  }

  componentWillLoad() {
    debug('nylas-event-card', 'componentWillLoad');
  }

  componentDidLoad() {
    debug('nylas-event-card', 'componentDidLoad');
  }

  disconnectedCallback() {
    debug('nylas-event-card', 'disconnectedCallback');
    const slots = ['header-title', 'header-subtitle', 'content'];
    checkForMissingSlots(slots, this.host);
  }

  @RegisterComponent<NylasFormCard, NylasSchedulerConfigConnector, Exclude<NylasSchedulerEditor['stores'], undefined>>({
    name: 'nylas-form-card',
    eventToProps: {},
    fireRegisterEvent: true,
  })
  render() {
    return (
      <Host>
        <div class="nylas-form-card" part="nfc">
          <div class="nylas-form-card__header" part="nfc__header">
            <slot name="header-title"></slot>
            <slot name="header-subtitle"></slot>
          </div>
          <div class="nylas-form-card__content">
            <slot name="content"></slot>
          </div>
        </div>
      </Host>
    );
  }
}
