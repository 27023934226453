@import '../../../common/styles/variables.scss';

:host {
  display: block;
  @include default-css-variables;
}

.nylas-form-card {
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--nylas-base-200);
}
.nylas-form-card__header {
  padding: 1rem;
  border-bottom: 1px solid var(--nylas-base-200);
}
